<template>
  <div
    class="page-template-content"
    v-html="blockContent.replace(/https:\/\/parkos/g, `https://${$paths.urlEnvPrefix}parkos`)"
  />
</template>

<script>
export default {
  props: {
    slug: undefined,
  },

  computed: {
    blockContent() {
      if (!this.template?.page_content && this.slug === 'footer_airports') {
        return `
          <ul>\r\n
          <li><strong>${this.$i18n('general.airports')}</strong></li>\r\n
          ${this.airports
            .map(
              (airport) =>
                `<li><a class="text-white hover:text-white" href="/${airport.slug}/" :aria-label="${airport.name}" >${airport.name}</a></li>\r\n`,
            )
            .slice(0, 5)
            .join('')}
          </ul>
          `;
      }
      if (this.slug === 'footer_airports') {
        return this.template?.page_content.replace('#airports', `https://${this.$paths.host}/#airports`) ?? '';
      }
      return this.template?.page_content ?? '';
    },
    template() {
      return this.$store.state.pageTemplate[this.slug]?.data[this.language.lang];
    },
    language() {
      return this.$store.state.language;
    },
    airports() {
      //  TODO CHECK available airports
      return this.$store.state.airports;
    },
  },
};
</script>

<style lang="scss">
.page-template-content {
  .text-white a,
  .text-white a:hover {
    color: inherit;
  }

  li > strong,
  li > b {
    @apply font-normal font-cocogoose;
  }
}
</style>

/* eslint-disable camelcase */
import { getInstance as getBFFInstance } from '~/services/bffService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
export const state = () => ({
  languages: [],
  language: {},
  translations: {},
  airports: [],
  pageContent: {},
  pageTemplate: {},
  metaPages: {},
  pageType: null,
});

export const actions = {
  async nuxtServerInit({ state, dispatch }, { app, req }) {
    const BFF = getBFFInstance('parkos', this.$config);
    // Initiate content
    const { host } = app.$paths;
    try {
      dispatch('experiment/setExperiments', req);

      state.languages = await BFF.getLanguages();
      state.language = Array.prototype.find.call(state.languages, (language) => language.domain === host);
      const { lang } = state.language;

      const slugs = ['footer_airports', 'footer_information', 'footer_about', 'footer_icons'];
      const [
        homeContent,
        termsContent,
        policyContent,
        aboutContent,
        translationData,
        airports,
        footerAirports,
        footerInformation,
        footerAbout,
        footerPaymentIcons,
      ] = await Promise.all([
        BFF.getPageContent('home', state.language.lang),
        BFF.getPageContent('terms-conditions', state.language.lang),
        BFF.getPageContent('privacy-policy', state.language.lang),
        BFF.getPageContent('about-us', state.language.lang),
        BFF.getTranslations(lang),
        BFF.getAirports(lang),
        ...slugs.map((_slug) => BFF.getPageTemplate(_slug, state.language.lang)),
      ]);

      state.pageContent = {
        home: { [lang]: homeContent[lang] },
        termsConditionsContent: { [lang]: termsContent[lang] },
        privacyPolicyContent: { [lang]: policyContent[lang] },
        aboutPageContent: { [lang]: aboutContent[lang] },
      };

      state.translations = translationData;
      state.airports = airports;

      state.pageTemplate = {
        footer_airports: footerAirports,
        footer_information: footerInformation,
        footer_about: footerAbout,
        footer_icons: footerPaymentIcons,
      };

      const metas = ['faq', 'internship-vacancy', 'partners'];
      const [faqMeta, jobsMeta, partnersMeta] = await Promise.all(
        metas.map((meta) => BFF.getPageContent(meta, state.language.lang)),
      );
      state.metaPages = {
        faqMeta: faqMeta[state.language.lang],
        jobsMeta: jobsMeta[state.language.lang],
        partnersMeta: partnersMeta[state.language.lang],
      };
    } catch (e) {
      console.log(e);
    }

    return null;
  },
  async subscribeToNewsLetter(_, payload) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { emailaddress, host } = payload;
    const responseStatus = await BFF.postNewsLetterSubscription(emailaddress, host);
    if (responseStatus.status === 201) {
      return {
        success: true,
        message: responseStatus.message,
      };
    }

    return {
      success: false,
      message: responseStatus.message,
    };
  },
};

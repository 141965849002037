<template>
  <div class="overflow-x-hidden" :class="{ fixed: false }">
    <Header @toggle="navToggle" />
    <div class="flex flex-col justify-center my-6 mx-auto align-center">
      <h1 v-if="error.statusCode === 404" class="text-3xl">Page not found</h1>
      <h1 v-else class="text-3xl">
        {{ error.message }}
      </h1>
      <NuxtLink to="/"> Home page </NuxtLink>
    </div>
    <client-only>
      <noscript>
        <iframe
          :src="`https://www.googletagmanager.com/ns.html?id=${language.gtm_key}&noscript=`"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe>
      </noscript>
    </client-only>
    <Footer />
  </div>
</template>

<script>
import Header from '~/components/header/Header.vue';
import Footer from '~/components/core/shared/footer/Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
  props: ['error'], // you can set a custom layout for the error page
  data() {
    return {
      navShown: false,
      fixed: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  methods: {
    navToggle(value) {
      this.navShown = value;
    },
  },
};
</script>

<template>
  <div class="footer">
    <div class="pt-4 pb-10 text-white bg-blue-600 sm:py-20">
      <div class="lg:px-0 md:px-0 xs:container px-10">
        <div class="flex flex-col place-content-between md:flex-row">
          <div class="order-2 mt-6 sm:mt-0 md:order-1">
            <Logo class="mb-6" />
            <div class="flex flex-col sm:flex-row sm:place-content-between md:flex-col md:place-content-start">
              <div class="cms-payment-icons">
                <!-- <div>
                  {{ $i18n('templates.trusted-payment') }}
                </div> -->
                <div>
                  <!-- <div class="flex mt-4"> -->
                  <!-- <img
                    v-for="paymentIcon in paymentIcons"
                    :key="paymentIcon"
                    class="w-8 h-auto mr-3"
                    :src="`${$paths.assetsUrl}img/payment-icons/${paymentIcon}.png`"
                  > -->
                  <PageTemplate class="text-md" :slug="'footer_icons'" :language="language" />
                </div>
              </div>
              <div class="font-sans mt-5 sm:mt-0 md:mt-5">
                <div class="text-md">
                  {{ $i18n('general.socials-follow-us') }}
                </div>
                <div class="mt-4">
                  <Socials icon-color="#206BF6" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="grid order-1 grid-cols-1 gap-0 place-content-between mb-6 xs:flex xs:grid-cols-2 xs:gap-4 md:order-2 md:gap-6 md:w-6/12 footer__footer-templates"
          >
            <template v-for="slug in slugs">
              <PageTemplate ref="templates" :key="slug" class="hidden xs:block" :slug="slug" :language="language" />
            </template>
            <template v-for="(slug, index) in slugs">
              <ToggleInfo
                v-if="parsedContent[slug]"
                :key="`${slug}alt`"
                ref="toggles"
                :data-slug="slug"
                class="border-b border-cornflower xs:hidden"
                :caret-active-class="`text-white`"
                :narrow="true"
                :class="{ '-mt-4': !index }"
              >
                <template #title>
                  {{ parsedContent[slug].title }}
                </template>
                <template #content>
                  <ul>
                    <template v-for="data in parsedContent[slug].content">
                      <li :key="data.url">
                        <a class="text-white" :href="data.url" :aria-label="data.text">
                          {{ data.text }}
                        </a>
                      </li>
                    </template>
                  </ul>
                </template>
              </ToggleInfo>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="py-6 font-sans text-white bg-chathams-blue-900 leading-6">
      <div class="px-5 xs:container sm:px-0">
        <div class="flex flex-col items-start place-content-between md:flex-row">
          <div class="order-2 text-14 md:text-16 mt-4 md:order-1 md:mt-0" v-html="$i18n('templates.copyright')" />
          <div class="text-14 flex order-1 xs:flex-row md-order-2">
            <a
              v-if="termsConditionsPageContent.url"
              :href="termsConditionsPageContent.url"
              class="text-white text-md hover:text-white hover:underline"
              >{{ termsConditionsPageContent.title }}</a
            >
            <a
              v-if="privacyPolicyPageContent.url"
              :href="privacyPolicyPageContent.url"
              class="text-white text-md hover:text-white hover:underline ml-12"
              >{{ privacyPolicyPageContent.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '~/components/Logo.vue';
import Socials from '~/components/Socials.vue';
import PageTemplate from '~/components/core/shared/PageTemplate.vue';
import ToggleInfo from '~/components/airport/ToggleInfo.vue';

export default {
  components: {
    ToggleInfo,
    Logo,
    Socials,
    PageTemplate,
  },
  data() {
    return {
      paymentIcons: [
        'payment_ideal',
        'payment_amex',
        'payment_mastercard',
        'payment_paypal',
        'payment_visa',
        'payment_star',
      ],
      parsedContent: {},
    };
  },
  computed: {
    slugs() {
      return ['footer_airports', 'footer_information', 'footer_about'];
    },
    language() {
      return this.$store.state.language;
    },
    termsConditionsContent() {
      return this.$store.state.pageContent.termsConditionsContent;
    },
    privacyPolicyContent() {
      return this.$store.state.pageContent.privacyPolicyContent;
    },
    termsConditionsPageContent() {
      if (this.termsConditionsContent && this.language && this.language.lang) {
        const currentContent = this.termsConditionsContent[this.language.lang];
        return {
          title: currentContent.title,
          url: `/${currentContent.slug}.html`,
        };
      }

      return { title: '', url: '' };
    },
    privacyPolicyPageContent() {
      if (this.privacyPolicyContent && this.language && this.language.lang) {
        const currentContent = this.privacyPolicyContent[this.language.lang];
        return {
          title: currentContent.title,
          url: `/${currentContent.slug}.html`,
        };
      }

      return { title: '', url: '' };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.templates.forEach((comp) => {
        const parsed = this.parseContent(comp.$el);
        this.parsedContent[comp.slug] = parsed;
      });
      this.parsedContent = { ...this.parsedContent };
    });
  },
  methods: {
    parseContent(ulElement) {
      if (!ulElement) {
        return undefined;
      }
      const title = ulElement.querySelector('li:first-child');
      const content = ulElement.querySelectorAll('li:not(:first-child)');

      if (!title || !content) {
        return undefined;
      }

      const data = [];
      content.forEach((e) => {
        const a = e.querySelector('a');
        if (!a) return;
        data.push({ text: e.innerText, url: a.href });
      });
      return { title: title.innerText, content: data };
    },
  },
};
</script>

<style lang="scss">
.footer {
  &__footer-templates {
    @media all and (max-width: 360px) {
      @apply grid-cols-1;
    }

    ul {
      li {
        @apply mb-3 font-sans;
        @media (min-width: 576px) {
          &:first-child {
            @apply mb-5 font-sans;
          }
        }
        a {
          &:hover {
            @apply underline;
          }
        }
      }
    }
  }

  .cms-payment-icons {
    @apply font-sans;
    .text-center {
      @apply text-left;
    }
    ul {
      @apply place-content-start mt-3 gap-y-3;
      .icon {
        &.px-4 {
          @apply px-1 py-0 rounded bg-white mr-3;
        }
        img {
          @apply w-auto h-6;
        }
      }

      & + div {
        @apply mt-3;
        & > img {
          @apply hidden;
        }
      }
    }
  }
}
</style>

<template>
  <div :class="{ fixed: navShown }" class="overflow-hidden leading-relative">
    <Highlight />
    <Header />
    <main>
      <Nuxt />
    </main>
    <client-only>
      <noscript>
        <iframe
          :src="`https://www.googletagmanager.com/ns.html?id=${gtmKey}&noscript=`"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe>
      </noscript>
    </client-only>
    <Footer />
    <noscript v-html="cheqTag.noScript" />
  </div>
</template>
<script>
import Header from '~/components/header/Header.vue';
import Footer from '~/components/core/shared/footer/Footer.vue';
import Highlight from '~/components/Highlight.vue';

export default {
  components: {
    Header,
    Footer,
    Highlight,
  },
  data() {
    return {
      navShown: false,
      gtmKey: undefined,
    };
  },
  head() {
    const script = [];

    if (this.cheqTag) {
      script.push(this.cheqTag.script);
    }

    return { script };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    cheqTag() {
      return this.$cheq(this.language.domain);
    },
  },
  mounted() {
    this.loadGTM();
  },
  created() {
    this.gtmKey = this.language?.gtm_key;
  },
  methods: {
    loadGTM() {
      const { gtmKey } = this;

      if (gtmKey) {
        /* eslint-disable */
        // @ts-ignore
        window.loadTagManager = function (w, d, s, l) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmKey}` + dl;
          f.parentNode.insertBefore(j, f);
        };
        if (typeof tagManagerDelay === 'undefined') {
          window.loadTagManager(window, document, 'script', 'dataLayer');
        }
        /* eslint-enable */
      }
    },
    navToggle(value) {
      this.navShown = value;
    },
  },
};
</script>

<style>
* {
  -webkit-font-smoothing: antialiased;
}

html {
  scroll-behavior: smooth;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"relative leading-normal bg-blue-600"},[_c('ReferralCashback'),_vm._v(" "),_c('div',{ref:"highlightElement",staticClass:"bg-blue-600"},[_c('section',{staticClass:"flex fixed z-30 flex-col w-full h-full bg-blue-600 md:container md:static md:flex-row md:items-center md:py-4 md:h-auto md:bg-transparent top-menu",class:{
        'top-menu--collapse': _vm.collapse,
        'top-menu--scrolled': _vm.scrollTop,
      }},[_c('div',{staticClass:"flex place-content-between px-3 w-full transition-all duration-200 md:p-0 md:w-auto header-logo-container",class:{
          'py-1': _vm.scrollTop && _vm.collapse,
          'py-4': !_vm.collapse || !_vm.scrollTop,
        }},[_c('div',{staticClass:"flex items-center"},[_c('a',{staticClass:"p-3 -ml-4px md:-ml-3 top-menu__logo-wrapper",attrs:{"href":"/","aria-label":"logo"},on:{"click":_vm.clear}},[_c('Logo')],1),_vm._v(" "),_c('PartnerLogo')],1),_vm._v(" "),_c('div',{staticClass:"inline-flex items-center"},[_c('a',{attrs:{"href":"/login/","aria-label":"login"},on:{"click":_vm.clear}},[_c('IconUser',{staticClass:"mr-3 w-5 h-5 md:hidden"})],1),_vm._v(" "),_c('ToggleMenu',{staticClass:"md:hidden top-menu__toggle-menu",attrs:{"open":!_vm.collapse},on:{"toggled":_vm.toggleMenu}})],1)]),_vm._v(" "),_c('nav',{staticClass:"flex overscroll-contain inset-x-0 top-36 flex-col flex-1 w-screen h-0 text-xl bg-white md:static md:text-base md:bg-transparent header-nav",class:{
          'header-nav--collapse': _vm.collapse,
          'header-nav--mega': _vm.megaMenuOpen,
        }},[_c('div',{ref:"scroller",staticClass:"flex overscroll-contain flex-col items-stretch w-full h-full md:overflow-y-visible md:static md:flex-row md:items-center",class:{
            'overflow-y-scroll': !_vm.isSearchActive && !_vm.languagesExpanded,
            relative: _vm.isSearchActive || _vm.languagesExpanded,
          }},[_c('ul',{staticClass:"header-nav__menu md:mx-auto flex md:flex-row flex-col min-h-max",class:{
              'header-nav__menu--toggled': _vm.megaMenuOpen,
              'hidden md:flex': _vm.languagesExpanded,
            }},[_c('li',{staticClass:"header-nav__menu__item",class:{ 'header-nav__menu__item--sub': _vm.megaMenuOpen }},[_c('div',{staticClass:"w-full text-black md:w-auto md:text-white"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('MegaMenu',{attrs:{"open":_vm.megaMenuOpen,"expression":_vm.searchExpression},on:{"toggled":_vm.megaMenuToggled}})],1)],1)]),_vm._v(" "),(_vm.zenDeskLangCode)?_c('li',{staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":`https://parkos.zendesk.com/hc/${_vm.zenDeskLangCode}`,"rel":"nofollow","aria-label":_vm.$i18n('general.customer-service')},on:{"click":_vm.clear}},[_c('div',{staticClass:"overflow-hidden flex-1 shrink-0 mr-2 w-full text-ellipsis whitespace-nowrap"},[_vm._v("\n                  "+_vm._s(_vm.$i18n('general.customer-service'))+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"block ml-auto text-black-alt-500 md:hidden"})],1)]):_vm._e(),_vm._v(" "),(_vm.aboutPageLink)?_c('li',{staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":_vm.aboutPageLink,"aria-label":`${_vm.aboutPageTitle}`},on:{"click":_vm.clear}},[_c('div',{staticClass:"overflow-hidden flex-1 shrink-0 mr-2 w-full text-ellipsis whitespace-nowrap"},[_vm._v("\n                  "+_vm._s(_vm.aboutPageTitle)+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"block ml-auto text-black-alt-500 md:hidden"})],1)]):_vm._e()]),_vm._v(" "),_c('ul',{staticClass:"flex flex-col md:flex-row header-nav__menu header-nav__menu--abs",class:{
              'header-nav__menu--sub': _vm.languagesExpanded,
              'hidden md:flex': _vm.megaMenuOpen,
            }},[_c('li',{staticClass:"header-nav__menu__item header-nav__menu__item--login"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":"/login/","aria-label":"login"},on:{"click":_vm.clear}},[_c('div',{staticClass:"overflow-hidden flex-1 shrink-0 mr-2 w-full text-ellipsis whitespace-nowrap"},[_vm._v("\n                  "+_vm._s(_vm.$i18n('templates.header-login'))+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"block ml-auto text-black-alt-500 md:hidden"})],1)]),_vm._v(" "),(_vm.faqMeta)?_c('li',{staticClass:"pr-0 header-nav__menu__item header-nav__menu__item--meta"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":_vm.faqPageLink,"aria-label":`${_vm.faqPageTitle}`},on:{"click":_vm.clear}},[_c('div',{staticClass:"overflow-hidden flex-1 shrink-0 mr-2 w-full text-ellipsis whitespace-nowrap"},[_vm._v("\n                  "+_vm._s(_vm.faqPageTitle)+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"block ml-auto text-black-alt-500 md:hidden"})],1)]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"pr-0 header-nav__menu__item"},[_c('MobileLanguages',{staticClass:"block md:hidden",attrs:{"expanded":_vm.languagesExpanded},on:{"toggled":_vm.languagesToggled}}),_vm._v(" "),_c('Languages',{staticClass:"hidden items-center font-sans md:flex"})],1)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
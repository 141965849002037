import { getInstance as getBFFInstance } from '~/services/bffService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
  selectedCategory: null,
  categories: [],
});

const mutations = {
  setCategoryList(state, payload) {
    state.categories = [];

    if (payload && payload.length) {
      state.categories = payload;
    }

    state.categories.push({
      id: null,
      name: 'All',
    });

    state.categories.sort((a, b) => a.id - b.id);
  },
};

const actions = {
  async getCategories({ commit, rootState }) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { lang } = rootState.language;
    const res = await BFF.getCategories(lang);
    await commit('setCategoryList', res?.categories);
  },
  async getCategoryBySlug({ state, rootState }, slug) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { lang } = rootState.language;
    const res = await BFF.getCategory(lang, slug);

    state.selectedCategory = res?.data?.categories?.[0];
    return res?.headers[['x-wp-total']];
  },
};

export default {
  state,
  mutations,
  actions,
};

import { getInstance as getBFFInstance } from '~/services/bffService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
  similarPosts: [],
  blogPage: [],
  post: null,
  list: null,
  featuredArticle: null,
});

const mutations = {
  setPosts(state, payload) {
    state.list = null;

    if (payload) {
      const { totalPage, totalCount, posts } = payload;

      state.list = {
        totalCount: parseInt(totalCount, 10),
        totalPage: parseInt(totalPage, 10),
        posts,
      };
    }
  },
  setSimilarPosts(state, payload) {
    state.similarPosts = [];

    if (payload && payload.length) {
      state.similarPosts = payload;
    }
  },
};

const actions = {
  async getPosts({ commit, rootState }, payload) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { lang } = rootState.language;
    const filter = { lang, ...payload?.filter };

    const res = await BFF.getPosts(filter);

    await commit('setPosts', res);
  },

  async getPostBySlug({ state, rootState }, slug) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { lang } = rootState.language;
    const res = await BFF.getPost(lang, slug);

    state.post = res?.data?.post;
    return res?.headers[['x-wp-total']];
  },

  async getSimilarPosts({ commit, rootState }, postId) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { lang } = rootState.language;
    const res = await BFF.getSimilarPosts(lang, postId);

    await commit('setSimilarPosts', res?.posts);
  },

  async getFeaturedPost({ state, rootState }, payload) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { lang } = rootState.language;
    const filter = { lang, ...payload?.filter, sticky: true };

    const res = await BFF.getPosts(filter);

    state.featuredArticle = res?.posts?.[0] || null;
  },
};

export default {
  state,
  mutations,
  actions,
};

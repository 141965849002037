<template>
  <div class="overflow-x-hidden">
    <Highlight />
    <Header />
    <main>
      <Nuxt />
    </main>
    <client-only>
      <noscript>
        <iframe
          :src="`https://www.googletagmanager.com/ns.html?id=${language.gtm_key}&noscript=`"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe>
      </noscript>
    </client-only>
  </div>
</template>

<script>
import Header from '~/components/header/Header.vue';
import Highlight from '~/components/Highlight.vue';

export default {
  components: {
    Header,
    Highlight,
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
};
</script>

<style>
* {
  -webkit-font-smoothing: antialiased;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}
</style>

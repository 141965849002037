<template>
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 0a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h24zm-7.75 9.333c-.364 0-.732.057-1.093.17a3.346 3.346 0 0 0-2.252 2.412c-.102.427-.129.855-.082 1.272a.128.128 0 0 1-.138.14 9.031 9.031 0 0 1-6.01-3.082L10.468 10a.18.18 0 0 0-.257-.023l-.039.046a3.458 3.458 0 0 0-.467 1.738c0 .937.376 1.82 1.029 2.463a3.097 3.097 0 0 1-.599-.209l-.188-.096a.18.18 0 0 0-.259.1l-.01.056a3.46 3.46 0 0 0 2.016 3.188 3.083 3.083 0 0 1-.462-.024l-.193-.031a.18.18 0 0 0-.214.184l.008.048a3.46 3.46 0 0 0 2.731 2.356 6.357 6.357 0 0 1-3.565 1.085h-.4a.264.264 0 0 0-.257.2.274.274 0 0 0 .13.302 9.454 9.454 0 0 0 4.758 1.285c1.462 0 2.83-.291 4.065-.864a8.98 8.98 0 0 0 2.975-2.233 9.794 9.794 0 0 0 1.822-3.057 9.67 9.67 0 0 0 .619-3.345v-.052c0-.177.08-.344.22-.458a6.82 6.82 0 0 0 1.174-1.228l.197-.28c.1-.15-.058-.339-.223-.266a6.328 6.328 0 0 1-1.103.373c.365-.347.65-.771.835-1.243l.073-.205a.18.18 0 0 0-.216-.231l-.048.02a6.36 6.36 0 0 1-1.687.698.447.447 0 0 1-.4-.1 3.446 3.446 0 0 0-2.283-.863z"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>

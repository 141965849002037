var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block md:inline-block md:static",class:{ relative: !_vm.externalShow },on:{"mouseenter":function($event){$event.stopPropagation();$event.preventDefault();return _vm.expandHover.apply(null, arguments)},"mouseleave":function($event){$event.stopPropagation();$event.preventDefault();return _vm.collapseHover.apply(null, arguments)}}},[_c('div',{ref:"airportsLabel",staticClass:"airports-label",class:{
      'airports-label--collapse': !_vm.show,
      'hidden md:block': _vm.externalShow,
    },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center airports-label__default"},[_vm._v("\n      "+_vm._s(_vm.$i18n('general.airports'))+"\n      "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 md:hidden"}),_vm._v(" "),_c('div',{staticClass:"hidden ml-1 md:block caret"})],1),_vm._v(" "),_c('div',{staticClass:"flex items-center text-base airports-label__back"},[_c('IconCaretRight',{staticClass:"mr-3 text-black-alt-500 rotate-180"}),_vm._v("\n      "+_vm._s(_vm.$i18n('templates.go-to-home'))+"\n    ")],1)]),_vm._v(" "),(_vm.show || _vm.externalShow)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],ref:"scroller",staticClass:"mega-menu text-black absolute inset-x-0 cursor-default z-50 md:top-[84px] md:shadow-section",class:{
      'mega-menu--half-scrolled': _vm.halfScrolled,
      'inset-0 md:inset-y-auto bg-white md:hidden': _vm.externalShow,
    }},[_c('div',{staticClass:"text-base",class:{ 'filtering-country': _vm.filteringCountry }},[(_vm.unfilteredCountries.length > 1)?_c('div',{staticClass:"hidden bg-silver-100 md:block"},[_c('div',{staticClass:"pb-3 md:container md:px-10 md:pt-7"},[_c('SearchInput',{staticClass:"relative mega-menu__search",attrs:{"expression":_vm.searchExpression},on:{"expressionChanged":function($event){_vm.searchExpression = $event}}}),_vm._v(" "),(!_vm.externalShow && _vm.countries.length)?_c('div',{staticClass:"flex flex-wrap justify-start py-4 mt-3 font-bold font-sans select-none md:flex mega-menu__countries"},[_c('div',{staticClass:"px-5 text-black-alt-800 cursor-pointer mega-menu__countries__country",class:{
                'mega-menu__countries__country--highlight': !_vm.filteringCountry,
              },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.filteringCountry = null}}},[_vm._v("\n              "+_vm._s(_vm.$i18n('general.all-countries'))+"\n            ")]),_vm._v(" "),_vm._l((_vm.countries),function(country){return _c('div',{key:country},[(_vm.countries.length > 1)?_c('div',{staticClass:"px-5 text-black-alt-800 cursor-pointer mega-menu__countries__country",class:{
                  'mega-menu__countries__country--highlight': _vm.filteringCountry === country,
                },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.filterCountry(country)}}},[_vm._v("\n                "+_vm._s(country)+"\n              ")]):_vm._e()])})],2):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bg-white select-none",class:_vm.countries.length > 1 ? 'md:pt-3 md:pb-10 ' : 'md:py-10'},[(!_vm.countries.length)?_c('div',{staticClass:"flex place-content-center px-7 mt-8 text-center md:px-10"},[_vm._v("\n          "+_vm._s(_vm.$i18n('customer.no-results'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"place-content-between md:container md:gap-y-4 md:px-10 mega-menu__grid",class:{
            'md:grid': !_vm.filteringCountry,
            'md:grid-cols-4': !_vm.filteringCountry && !_vm.isUSDomain,
            'md:grid-cols-1': !_vm.filteringCountry && _vm.isUSDomain,
          }},[_vm._l((_vm.countries),function(country){return [(_vm.countryAirportsFiltered[country].length)?[_c('div',{key:country,staticClass:"mega-menu__grid__country",class:{
                  grid: _vm.filteringCountry || _vm.isUSDomain,
                  'md:grid-cols-1': _vm.columns === 1 && !_vm.isUSDomain,
                  'md:grid-cols-2': _vm.columns === 2 && !_vm.isUSDomain,
                  'md:grid-cols-3': _vm.columns === 3 || _vm.isUSDomain,
                  'md:grid-cols-4': _vm.columns === 4 && !_vm.isUSDomain,
                }},[(_vm.countries.length > 1)?_c('div',{staticClass:"mega-menu__grid__country__title",class:{
                    'mega-menu__grid__country__title--full': _vm.filteringCountry || _vm.isUSDomain,
                  }},[_vm._v("\n                  "+_vm._s(country)+"\n                ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.countryAirportsFiltered[country]),function(airport){return [_c('div',{key:airport.id,staticClass:"md:pr-2 md:my-1 mega-menu__grid__airport"},[_c('a',{staticClass:"flex items-center py-3 px-5 hover:text-orange-500 hover:no-underline border-b border-athens md:p-0 md:border-none",class:{
                        'text-orange-500': _vm.selectedAirportByPicker.id === airport.id,
                        'text-black-alt-500': _vm.selectedAirportByPicker.id !== airport.id,
                      },attrs:{"href":`/${airport.slug}/`},on:{"click":function($event){$event.stopPropagation();return _vm.clear.apply(null, arguments)}}},[_c('div',{staticClass:"w-full pr-2 md:pr-0 whitespace-nowrap overflow-hidden text-ellipsis"},[_vm._v("\n                        "+_vm._s(airport.name)+"\n                      ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"text-black-alt-500 md:hidden ml-auto transform scale-75"})],1)])]})],2)]:_vm._e()]})],2)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
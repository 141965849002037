<template>
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.688 0H4.312A4.312 4.312 0 0 0 0 4.312v23.376A4.312 4.312 0 0 0 4.312 32h11.529l.02-11.435H12.89a.7.7 0 0 1-.701-.698l-.014-3.686a.701.701 0 0 1 .7-.704h2.966v-3.562c0-4.133 2.524-6.383 6.211-6.383h3.026c.387 0 .7.313.7.7v3.109c0 .387-.313.7-.7.7l-1.856.002c-2.006 0-2.394.952-2.394 2.35v3.084h4.406c.42 0 .746.367.696.784l-.437 3.686a.701.701 0 0 1-.696.618h-3.95L20.829 32h6.86A4.312 4.312 0 0 0 32 27.688V4.312A4.312 4.312 0 0 0 27.688 0z"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 0a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h24zM12.006 9.437c-.452-.288-1.027.058-1.027.62v11.887c0 .56.575.907 1.027.619l9.31-5.944c.44-.28.44-.958 0-1.238z"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <rect fill="currentColor" width="24" height="24" rx="4" />
      <path
        d="M9.336 20.586A9.104 9.104 0 0 0 12 21a9 9 0 1 0-9-9c0 3.825 2.403 7.11 5.796 8.406-.081-.702-.162-1.863 0-2.664l1.035-4.446s-.261-.522-.261-1.35c0-1.242.774-2.169 1.656-2.169.774 0 1.134.567 1.134 1.296 0 .774-.513 1.881-.774 2.943-.153.882.468 1.656 1.368 1.656 1.602 0 2.844-1.71 2.844-4.122 0-2.16-1.548-3.636-3.771-3.636-2.538 0-4.032 1.89-4.032 3.879 0 .774.252 1.557.666 2.07.081.054.081.126.054.261l-.261.981c0 .153-.099.207-.252.099-1.152-.504-1.818-2.142-1.818-3.465 0-2.844 2.016-5.427 5.904-5.427 3.096 0 5.508 2.223 5.508 5.175 0 3.096-1.917 5.58-4.662 5.58-.873 0-1.728-.468-2.034-1.017l-.603 2.133c-.207.774-.774 1.809-1.161 2.43v-.027z"
        :fill="iconColor"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPinterest',
  props: {
    iconColor: {
      type: String,
      default: '#206BF6',
    },
  },
};
</script>

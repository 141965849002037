// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

class BffService {
  constructor($config) {
    this.axiosInstance = axios.create({ baseURL: $config.BFF_BASE_URL });
    this.axiosInstance.interceptors.request.use(
      (request) => request,
      (error) => {
        console.log('BFF Request Error: ', JSON.stringify(error));
        return error;
      },
    );
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log('BFF Response error: ', JSON.stringify(error));
        return error;
      },
    );
  }

  async postNewsLetterSubscription(email, host) {
    try {
      const self = this;
      const res = await self.axiosInstance.post('/newsletter/subscribe', {
        email,
        host,
      });
      return res.data;
    } catch (e) {
      return {
        status: 400,
        message: 'blog.email-subscription-error',
      };
    }
  }

  async getPageContent(devtitle, lang) {
    const self = this;
    const res = await self.axiosInstance.get(`pages/${devtitle}/content.json`, {
      params: { lang },
    });

    return res.data;
  }

  async getBlogList(lang) {
    const self = this;
    const res = await self.axiosInstance.get('/blog', { params: { lang } });
    return res.data;
  }

  async getLanguages() {
    try {
      const self = this;
      const res = await self.axiosInstance.get('languages');
      return res.data.data;
    } catch (error) {
      return error;
    }
  }

  async getTranslations(language) {
    const self = this;
    const res = await self.axiosInstance.get(`translations/${language}/blogs`);
    return res.data;
  }

  async getAirports(lang, limit = 250, orderBy = 'locations_content.maintitle') {
    const self = this;
    const res = await self.axiosInstance.get('airports', {
      params: { lang, limit, orderBy },
    });

    return res.data.data;
  }

  async getAirport(slug, lang) {
    const self = this;
    const airports = await self.getAirports(lang);
    return Array.prototype.find.call(airports, (airport) => airport.slug === slug);
  }

  async getAirportData(slug, lang) {
    const self = this;
    const airport = await self.getAirport(slug, lang);
    const res = await self.axiosInstance.get(`airports/${airport.id}/details`);
    return res.data;
  }

  async getPageTemplate(slug, lang) {
    const self = this;
    const res = await self.axiosInstance.get('page_template', {
      params: { slug, lang },
    });
    return res.data;
  }

  async getCategories(lang) {
    const self = this;
    const res = await self.axiosInstance.get('blog/categories', {
      params: { lang },
    });
    return res.data;
  }

  async getCategory(lang, slug) {
    const self = this;
    const res = await self.axiosInstance.get(`blog/categories/${slug}`, {
      params: { lang },
    });
    return res;
  }

  async getPosts(filter) {
    const self = this;
    const res = await self.axiosInstance.get('blog/posts', {
      params: { ...filter },
    });
    return res.data;
  }

  async getPost(lang, slug) {
    const self = this;
    const res = await self.axiosInstance.get(`blog/posts/${slug}`, {
      params: { lang },
    });
    return res;
  }

  async getSimilarPosts(lang, postId, limit = 3) {
    const self = this;
    const res = await self.axiosInstance.get(`blog/similar-posts/${postId}`, {
      params: { lang, limit },
    });
    return res.data;
  }

  async getBlogPage(lang, slug) {
    const self = this;
    const res = await self.axiosInstance.get('blog', {
      params: { lang, slug },
    });
    return res.data;
  }

  async getBlogPageslugs() {
    const self = this;
    const res = await self.axiosInstance.get('/cloud-routes/blogpages');
    return res.data;
  }
}
const bffInstances = {};

function getInstance(name, config) {
  if (!(name in bffInstances)) {
    bffInstances[name] = new BffService(config);
  }

  return bffInstances[name];
}

export { getInstance }; // eslint-disable-line import/prefer-default-export

<template>
  <svg
    width="113.386px"
    height="113.386px"
    viewBox="0 0 113.386 113.386"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs><path id="a" d="M0 0h113.386v113.386H0z" /></defs>
    <clipPath id="b"><use xlink:href="#a" overflow="visible" /></clipPath>
    <path
      d="M100.136 47.884h-9.854c.718 2.787 1.142 5.699 1.142 8.71 0 19.238-15.592 34.83-34.827 34.83-19.234 0-34.827-15.592-34.827-34.83 0-3.011.426-5.923 1.144-8.71h-9.855v47.893c0 2.401 1.95 4.35 4.358 4.35h78.361a4.354 4.354 0 0 0 4.358-4.35V47.884zm0-30.473a4.355 4.355 0 0 0-4.358-4.353H82.72a4.354 4.354 0 0 0-4.354 4.353v13.063a4.353 4.353 0 0 0 4.354 4.353h13.058a4.355 4.355 0 0 0 4.358-4.353V17.411zm-43.54 17.416c-12.023 0-21.768 9.744-21.768 21.767 0 12.021 9.745 21.77 21.768 21.77s21.77-9.749 21.77-21.77c0-12.023-9.747-21.767-21.77-21.767m43.54 78.365H13.058C5.847 113.192 0 107.343 0 100.126V13.058C0 5.846 5.847 0 13.058 0h87.078c7.212 0 13.058 5.846 13.058 13.058v87.068c-.001 7.217-5.846 13.066-13.058 13.066"
      clip-path="url(#b)"
      fill-rule="evenodd"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>

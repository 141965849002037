// eslint-disable-next-line func-names
export default async function ({ store, error, route }) {
  try {
    const posts = await store.dispatch('post/getPostBySlug', route.params.value);

    if (Number(posts) === 0) {
      const categories = await store.dispatch('category/getCategoryBySlug', route.params.value);

      if (Number(categories) === 0) {
        return error({ statusCode: 404, message: 'Page not found' });
      }
      store.state.pageType = 'category';
    } else {
      store.state.pageType = 'post';
    }
    return true;
  } catch (e) {
    console.error('Error in middleware:', e);
    return error({ statusCode: 503, message: 'Internal Server Error' });
  }
}

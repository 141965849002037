<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="toggle-info-component select-none">
    <div
      class="cursor-pointer flex items-center place-content-between"
      :class="{ 'py-6 text-lg': !narrow, 'py-4': narrow }"
      @click.stop.prevent="toggled = !toggled"
    >
      <slot name="title" :toggled="toggled" />
      <CaretDown
        class="toggle-info-component__caret stroke-current transition-all"
        :class="{
          'rotate-180 transform': toggled,
          [caretActiveClass]: toggled,
          'text-black-600': !toggled,
        }"
      />
    </div>
    <div v-if="toggled" class="mt-3 mb-5">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import CaretDown from '~/components/icons/IconCaretDown.vue';

export default {
  components: {
    CaretDown,
  },
  props: {
    caretActiveClass: {
      type: String,
      default: () => 'text-orange-500',
      required: false,
    },
    narrow: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
};
</script>

<style lang="scss">
.toggle-info-component {
  $self: &;
  @media (min-width: 768px) {
    &:hover {
      #{$self}__caret {
        @apply text-orange-500;
      }
    }
  }
}
</style>

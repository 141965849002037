import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

const KEY = ''
const DISABLED = true
const HIDE_ON_LOAD = true
const SETTINGS = {"webWidget":{"color":{"theme":"#0a83ef"}}}

export default function (context) {
  Vue.use(Zendesk, {
    key: KEY,
    disabled: DISABLED,
    hideOnLoad: HIDE_ON_LOAD,
    settings: SETTINGS
  })
}

import { getInstance as getBFFInstance } from '~/services/bffService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = () => ({
  blogPage: [],
});

const mutations = {
  setBlogPage(state, payload) {
    state.blogPage = [];

    if (payload && payload.length) {
      state.blogPage = payload;
    }
  },
};

const actions = {
  async getBlogPage({ commit, rootState }, slug) {
    const BFF = getBFFInstance('parkos', this.$config);
    const { lang } = rootState.language;
    const res = await BFF.getBlogPage(lang, slug);

    await commit('setBlogPage', res);
  },
};

export default {
  state,
  mutations,
  actions,
};

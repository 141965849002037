<template>
  <div class="flex flex-row">
    <a target="_blank" aria-label="facebook icon" href="https://www.facebook.com/ParkosInternational/">
      <IconFacebook :class="['w-6 h-6 fill-current', iconClass]" />
    </a>
    <a target="_blank" aria-label="youtube icon" href="https://www.youtube.com/c/ParkosInternational/">
      <IconYoutube :class="['w-6 h-6 fill-current ml-3', iconClass]" />
    </a>
    <a target="_blank" aria-label="twitter icon" href="https://twitter.com/ParkosInt/">
      <IconTwitter :class="['w-6 h-6 fill-current ml-3', iconClass]" />
    </a>
    <a href="https://www.instagram.com/parkos.international/" aria-label="instagram icon" target="_blank">
      <IconInstagram :class="['w-6 h-6 fill-current ml-3', iconClass]" />
    </a>
    <a href="https://www.pinterest.com/parkosinternational/" aria-label="pinterest icon" target="_blank">
      <IconPinterest :class="['w-6 h-6 fill-current ml-3', iconClass]" :icon-color="iconColor" />
    </a>
  </div>
</template>

<script>
import IconTwitter from '~/components/icons/IconTwitter.vue';
import IconYoutube from '~/components/icons/IconYoutube.vue';
import IconFacebook from '~/components/icons/IconFacebook.vue';
import IconInstagram from '~/components/icons/IconInstagram.vue';
import IconPinterest from '~/components/icons/IconPinterest.vue';

export default {
  components: {
    IconTwitter,
    IconYoutube,
    IconFacebook,
    IconInstagram,
    IconPinterest,
  },
  props: {
    iconClass: {
      type: String,
      default: 'text-white',
    },
    iconColor: {
      type: String,
      default: '#fff',
    },
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    twitter() {
      return this.language.socials.twitter;
    },
    facebook() {
      return this.language.socials.facebook;
    },
    youtube() {
      return this.language.socials.youtube;
    },
  },
};
</script>

<style></style>

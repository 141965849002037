const state = () => ({
  flags: {
    experiments: {},
    features: {},
  },
});

const getters = {
  isExperimentEnabled: (state) => (name) => !!state.flags.features?.[name],
  experimentVariant: (state) => (name) => state.flags.features?.[name],
};

const actions = {
  setExperiments({ state }, req) {
    try {
      if (req.headers?.['x-features']) {
        state.flags.features = JSON.parse(req.headers['x-features']);
      }

      if (req.headers?.['x-experiments']) {
        const result = {};
        req.headers?.['x-experiments'].split(',').forEach((element) => {
          const [elKey, elValue] = element.split('=');
          result[elKey] = elValue;
        });
        state.flags.experiments = result;
      }
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  getters,
  actions,
};
